import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // Si tienes un archivo CSS, de lo contrario, puedes omitir esta línea.

function App() {
  return (
    <div>
      <h1>Hello, React!</h1>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
